import media from '@/styles/media'
import {
  hubspotFormCss,
  maxWidthSectionCss,
  paddingSectionCss,
  paddingTextCss,
  StyledSectionProps
} from '@/styles/shared'
import { styled } from 'styled-components'

export const StyledFooterWrapper = styled.div`
  ${paddingSectionCss}
  border-top: 1px solid ${({ theme }) => theme.colors.beluga};
  width: 100vw;
  background-color: ${({ theme }) => theme.colors.navalNight};
  color: ${({ theme }) => theme.colors.white};
`

export const StyledFooterContainer = styled.div`
  ${maxWidthSectionCss}
  margin: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
`

export const StyledCompanyLogo = styled.img`
  display: block;
  width: 256px;
`

export const StyledAddressList = styled.ul`
  display: block;
  margin-bottom: 1.4vw;
`

export const StyledAddressListItem = styled.li`
  float: left;
  padding-right: 60px;
  margin-top: 7.2vw;
  ${media.md`
    margin-top: 26px;
  `}
  ul {
    display: grid;
    row-gap: 12px;
    font-size: ${({ theme }) => theme.fontSizes.copy.small};
    li {
      strong {
        font-weight: ${({ theme }) => theme.fontWeights.semiBold};
      }
      ${media.md`
        font-size: ${({ theme }) => theme.fontSizes.copy.medium};
      `}
    }
  }
`

export const StyledFlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
`

export const StyledFlexRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 24px;
  ${media.md`
    gap: 48px;
  `}
`

export const StyledFooterContent = styled.div`
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-between;
  align-items: flex-start;
  gap: 24px;
  ${media.md`
    gap: 48px;
    flex-direction: row;
  `}
`

export const StyledSocialLinksContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 12px 0;
  img {
    width: 36px;
  }
  align-items: center;
  justify-content: flex-start;
  ${media.md`
    width: auto;
  `}
  ${media.lg`
    gap: 48px;
    flex-direction: row;
  `}
`

export const StyledCertificationsContainer = styled.div`
  padding-top: 48px;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-self: center;
  ${media.tablet`
    justify-content: space-evenly;
    align-self: flex-end;
    width: 70%;
  `}
`

export const StyledLinksContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;
  padding: 12px 0;
  row-gap: 24px;
  ${media.tablet`
    justify-content: space-evenly;
  `}
`

export const StyledLinkWrapper = styled.div`
  width: 35%;
  min-width: 136px;
`

export const StyledImageLinkWrapper = styled.div`
  display: flex;
  a {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    width: 100%;
    heigh: 100%;
  }
  img {
    max-width: 66px;
  }
`

export const StyledFormWrapper = styled.div<
  StyledSectionProps & {
    $primaryBtnBackgroundColor?: string | null
    $primaryBtnTextColor?: string | null
  }
>`
  ${hubspotFormCss}
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 296px;
  max-width: 384px;
  padding: 12px 0;
  .actions {
    padding: 0;
  }
  .hs-button {
    justify-content: flex-start;
    margin: 0;
  }
`

export const StyledLegalFooter = styled.div`
  ${paddingTextCss}
  background-color: ${({ theme }) => theme.colors.beluga};
  color: ${({ theme }) => theme.colors.navalNight};
  div.legal-contents {
    ${maxWidthSectionCss}
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  div.legal-links {
    display: flex;
    column-gap: 12px;
    align-items: center;
    justify-content: flex-end;
  }
`
