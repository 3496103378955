import { styled } from 'styled-components'
import media from '@/styles/media'
import { maxWidthSectionCss, paddingSectionCss, StyledSectionProps, xPaddingTextCss } from '@/styles/shared'

export const StyledDesktopNav = styled.div`
  display: none;
  ${media.tablet`
    ${xPaddingTextCss}
    display: block;
    height: 100%;
    width: 100%;
  `}
`

export const StyledDesktopSubNavWrapper = styled.div`
  display: none;
  ${media.tablet`
    display: block;
  `}
`

export const StyledSubNavContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.subnautical};
  position: relative;
  padding: 24px;
`

export const StyledNavContents = styled.div`
  display: none;
  a.primary-cta {
    display: none;
  }
  ${media.tablet`
    ${maxWidthSectionCss}
    display: flex;
    margin: auto;
    position: relative;
    z-index: 1;
    align-items: center;
    gap: 48px;
    height: 100%;
    div.primary-cta {
      display: flex;
      align-items: center;
      justify-content: center;
      a {
        color: ${({ theme }) => theme.colors.white};
        background-color: ${({ theme }) => theme.colors.devilsAdvocate};
        border-radius: ${({ theme }) => theme.borders.radius};
        font-weight: ${({ theme }) => theme.fontWeights.semiBold};
        width: max-content;
        padding: 4px 16px;
      }
    }
  `}
  ${media.lg`
    gap: 96px;
  `}
`

export const StyledNavItemsContainer = styled.div`
  display: none;
  ${media.tablet`
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    gap: 24px;
    font-family: ${({ theme }) => theme.fonts.europaRegular};
    font-size: ${({ theme }) => theme.fontSizes.copy.large};
    button,
    a {
      font-size: inherit;
      font-family: inherit;
      background: none;
      border: none;
      &:hover {
        color: ${({ theme }) => theme.colors.devilsAdvocate};
        text-decoration: underline;
        cursor: pointer;
      }
    }
  `}
  ${media.lg`
    gap: 48px;
  `}
`

export const StyledNavGroupContainer = styled.div`
  display: none;
  ${media.tablet`
    ${maxWidthSectionCss}
    display: block;
    margin: auto;
    overflow: auto;
    max-height: 92vh;
  `}
`

export const StyledNavGroupColumn = styled.div`
  display: none;
  ${media.tablet`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 24px;
    flex: 1;
    height: min-content;
    min-width: 20%;
    h5.group-title {
      font-size: ${({ theme }) => theme.fontSizes.copy.medium};
      font-weight: ${({ theme }) => theme.fontWeights.bold};
      display: block;
      text-decoration: underline;
      text-decoration-color: ${({ theme }) => theme.colors.silver};
      text-underline-offset: 0.4em;
    }
  `}
`

export const StyledNavGroupRow = styled.div`
  display: none;
  ${media.tablet`
    display: flex;
    color: ${({ theme }) => theme.colors.navalNight};
    flex-wrap: wrap;
    column-gap: 24px;
    row-gap: 48px;
    justify-content: space-between;
    align-items: flex-start;
    height: min-content;
    border-radius: 10px;
    a {
      flex: 1;
      min-width: 25%;
      font-size: ${({ theme }) => theme.fontSizes.copy.medium};
      font-weight: ${({ theme }) => theme.fontWeights.regular};
    }
  `}
`

export const StyledPrimaryCtaWrapper = styled.div<StyledSectionProps>`
  display: none;
  ${media.tablet`
    display: flex;
    width: 100%;
    height: 100%;
    color: ${({ theme }) => theme.colors.navalNight};
    justify-content: center;
    align-items: center;
    a.primary-cta {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding-bottom: 2px;
      gap: 24px;
      width: 100%;
      font-size: ${({ theme }) => theme.fontSizes.copy.large};
      font-weight: ${({ theme }) => theme.fontWeights.semiBold};
      border-bottom: 2px solid ${({ theme }) => theme.colors.devilsAdvocate};
      transition: all 0.3s ease-in;
      &:hover {
        gap: 96px;
      }
      span.chev-right {
        &:after {
          content: '';
          justify-content: center;
          align-items: center;
          display: flex;
          align-self: center;
          margin: auto;
          width: 12px;
          height: 12px;
          border-top: solid 2px;
          border-left: solid 2px;
          transform: rotate(135deg);
        }
        &:hover:after {
          border-color: ${({ theme }) => theme.colors.devilsAdvocate};
        }
        &:hover {
          color: ${({ theme }) => theme.colors.devilsAdvocate};
        }
      }
    }
  `}
`

export const StyledNavGroupAnimationContainer = styled.div`
  display: none;
  ${media.tablet`
    display: block;
    ${paddingSectionCss}
    background-color: ${({ theme }) => theme.colors.beluga};
    border-radius: 10px;
  `}
`

export const StyledNavGroupTabs = styled.div`
  display: none;
  ${media.tablet`
    display: flex;
    margin-left: 96px;
    position: relative;
    justify-content: flex-start;
    button {
      background-color: ${({ theme }) => theme.colors.navalNight};
      color: ${({ theme }) => theme.colors.white};
      border-top-right-radius: 10px;
      border-top-left-radius: 10px;
      text-transform: capitalize;
      cursor: pointer;
      border: none;
      color: inherit;
      font-family: inherit;
      font-size: ${({ theme }) => theme.fontSizes.copy.large};
      font-weight: ${({ theme }) => theme.fontWeights.semiBold};
      padding: 9px 18px;
    }
    button.active {
      color: ${({ theme }) => theme.colors.navalNight};
      background-color: ${({ theme }) => theme.colors.beluga};
    }
    button.close {
      &:hover {
        cursor: pointer;
        color: ${({ theme }) => theme.colors.devilsAdvocate};
      }
      color: ${({ theme }) => theme.colors.white};
      position: absolute;
      bottom: 0;
      right: 0;
      margin-right: 12px;
      background: none;
      border: none;
      font-size: ${({ theme }) => theme.fontSizes.copy.medium};
      font-weight: ${({ theme }) => theme.fontWeights.bold};
      font-family: ${({ theme }) => theme.fonts.europaRegular};
    }
  `}
`
