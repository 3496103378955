import React, { useState } from 'react'
import { StyledMobileNavItemsContainer, StyledMobileSubNavContainer, StyledToggleButton } from './styles'
import NavItem from '../NavItem'

import type { FC, PropsWithChildren } from 'react'
import type { NavGroupContent, NavGroupItemContent } from '@/types'
import type { NavLinkItemContent } from '../NavItem'
import { AnimatePresence, motion } from 'framer-motion'

const ToggleGroup: FC<
  PropsWithChildren<{
    text: string
    isActive: boolean
    handleToggle: () => void
    className?: string
    framerKey?: string | number
  }>
> = props => (
  <>
    <StyledToggleButton
      className={props.className}
      onClick={() => {
        props.handleToggle()
      }}
    >
      {props.text}
      {props.isActive ? <span className='arrow-up' /> : <span className='arrow-down' />}
    </StyledToggleButton>
    <AnimatePresence mode='wait' initial={false}>
      {props.isActive ? (
        <motion.div
          className='toggle-group'
          key={props.framerKey ?? 'mobile-sub-nav'}
          initial={{ height: 0, opacity: 0 }}
          animate={{ height: 'auto', opacity: 1 }}
          transition={{
            height: { ease: 'backIn', duration: 0.3 },
            opacity: { ease: 'easeIn', duration: 0.2 }
          }}
          exit={{ height: 0, opacity: 0 }}
        >
          {props.children}
        </motion.div>
      ) : null}
    </AnimatePresence>
  </>
)

const MobileNestedSubNav: FC<{
  callToAction?: NavLinkItemContent | null
  items: NavGroupItemContent[]
}> = props => {
  const [activeSubNav, setActiveSubNav] = useState<number | null>(null)
  return (
    <StyledMobileNavItemsContainer $nested>
      {props.items?.map((navItem, index) => {
        if (navItem.__typename === 'CMS_NavGroup') {
          const { title: groupTitle, itemsCollection, callToAction: nestedCta } = navItem as NavGroupContent

          const nestedItems = itemsCollection?.items as NavGroupItemContent[]
          return (
            <React.Fragment key={index}>
              <ToggleGroup
                className='top-level-item'
                framerKey={`nested-sub-nav-${index}`}
                isActive={activeSubNav === index}
                text={groupTitle ?? ''}
                handleToggle={() => {
                  if (activeSubNav === index) {
                    setActiveSubNav(null)
                  } else {
                    setActiveSubNav(index)
                  }
                }}
              >
                <>
                  {props.callToAction ?? nestedCta ? (
                    <NavItem
                      className='primary-cta'
                      {...((nestedCta ?? props.callToAction) as NavLinkItemContent)}
                    >
                      <span className='red-arrow'>&nbsp;&rarr;</span>
                    </NavItem>
                  ) : null}
                  {nestedItems?.map((nestedItem, nestedIndex) => {
                    if (nestedItem?.__typename === 'CMS_NavGroup') {
                      const { title: groupTitle, itemsCollection } = nestedItem as NavGroupContent

                      const activeItems = itemsCollection?.items as NavGroupItemContent[]

                      return (
                        <div className='nested-nav-group' key={nestedIndex}>
                          {groupTitle ? <h5 className='group-title'>{groupTitle}</h5> : null}
                          {activeItems.map((activeItem, activeIndex) => (
                            <NavItem key={activeIndex} {...(activeItem as NavLinkItemContent)} />
                          ))}
                        </div>
                      )
                    } else {
                      return <NavItem key={nestedIndex} {...(nestedItem as NavLinkItemContent)} />
                    }
                  })}
                </>
              </ToggleGroup>
            </React.Fragment>
          )
        } else {
          return <NavItem key={index} className='top-level-item' {...(navItem as NavLinkItemContent)} />
        }
      })}
    </StyledMobileNavItemsContainer>
  )
}

const MobileSubNav: FC<{
  callToAction?: NavLinkItemContent | null
  items?: NavGroupItemContent[]
}> = props => {
  const [activeSubNav, setActiveSubNav] = useState<number | null>(null)
  return (
    <StyledMobileSubNavContainer>
      <StyledMobileNavItemsContainer>
        {props?.items?.map((navItem, index) => {
          if (navItem.__typename === 'CMS_NavGroup') {
            const { title: groupTitle, itemsCollection, callToAction } = navItem as NavGroupContent

            return (
              <React.Fragment key={index}>
                <ToggleGroup
                  framerKey={`sub-nav-${index}`}
                  className='top-level-item'
                  isActive={activeSubNav === index}
                  text={groupTitle ?? ''}
                  handleToggle={() => {
                    if (activeSubNav === index) {
                      setActiveSubNav(null)
                    } else {
                      setActiveSubNav(index)
                    }
                  }}
                >
                  <MobileNestedSubNav
                    callToAction={callToAction as NavLinkItemContent}
                    items={itemsCollection?.items as NavGroupItemContent[]}
                  />
                </ToggleGroup>
              </React.Fragment>
            )
          } else {
            return <NavItem key={index} className='top-level-item' {...(navItem as NavLinkItemContent)} />
          }
        })}
        {props.callToAction ? (
          <NavItem className='top-level-item' {...(props.callToAction as NavLinkItemContent)} />
        ) : null}
      </StyledMobileNavItemsContainer>
    </StyledMobileSubNavContainer>
  )
}

export default MobileSubNav
