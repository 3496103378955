import { FooterContent, InternalLinkContent } from '@/types'
import React from 'react'
import type { FC } from 'react'
import { InternalLink } from '@/components/PageSections/sections/CallToAction'
import type { InternalLinkProps } from '@/components/PageSections/sections/CallToAction/InternalLink'
import HubspotForm from '@/components/HubspotForm'
import {
  StyledFooterWrapper,
  StyledFormWrapper,
  StyledLinksContainer,
  StyledCertificationsContainer,
  StyledCompanyLogo,
  StyledFlexRow,
  StyledLinkWrapper,
  StyledImageLinkWrapper,
  StyledSocialLinksContainer,
  StyledFooterContent,
  StyledFooterContainer,
  StyledLegalFooter,
  StyledAddressList,
  StyledAddressListItem
} from './styles'
import theme from '@/styles/theme'

const { colors } = theme

const Footer: FC<FooterContent> = ({
  companyLogo,
  siteLinksCollection,
  socialMediaLinksCollection,
  certificationsCollection,
  subscribeForm,
  legalText,
  legalLinksCollection
}) => {
  return (
    <>
      <StyledFooterWrapper>
        <StyledFooterContainer>
          <StyledFlexRow>
            <StyledCompanyLogo
              alt={(companyLogo?.description ?? companyLogo?.title) as string}
              src={(companyLogo?.url + '?q=100') as string}
            />
          </StyledFlexRow>
          <StyledFlexRow>
            <StyledAddressList>
              <StyledAddressListItem>
                <ul>
                  <li>
                    <strong>Toronto | HQ</strong>
                  </li>
                  <li>401 Bay Street, Suite 1600</li>
                  <li>Toronto, Ontario, Canada</li>
                  <li>M5H 2Y4</li>
                </ul>
              </StyledAddressListItem>
              <StyledAddressListItem>
                <ul>
                  <li>
                    <strong>San Francisco | HQ</strong>
                  </li>
                  <li>580 California Street, 12th floor</li>
                  <li>San Francisco, CA, USA</li>
                  <li>94104</li>
                </ul>
              </StyledAddressListItem>
            </StyledAddressList>
          </StyledFlexRow>
          <StyledFooterContent>
            <StyledFormWrapper $primaryBtnBackgroundColor={colors.white} $primaryBtnTextColor={colors.navalNight}>
              <HubspotForm
                id='footer-form'
                options={{
                  portalId: subscribeForm?.hubId as string,
                  formId: subscribeForm?.formId as string,
                  css: '.hs-field-desc { display: none }'
                }}
              />
            </StyledFormWrapper>
            <StyledFlexRow>
              <StyledLinksContainer>
                {siteLinksCollection?.items.map((link, index) => {
                  const { linkTo, label } = link as InternalLinkContent
                  return (
                    <StyledLinkWrapper key={index}>
                      <InternalLink {...(linkTo as InternalLinkProps)}>{label}</InternalLink>
                    </StyledLinkWrapper>
                  )
                })}
              </StyledLinksContainer>
              <StyledSocialLinksContainer>
                {socialMediaLinksCollection?.items.map((link, index) => {
                  return (
                    <a key={index} href={link?.url as string} target='_blank' rel='noreferrer'>
                      <img
                        src={link?.image?.url as string}
                        alt={link?.image?.description ?? (link?.image?.title as string)}
                      />
                    </a>
                  )
                })}
              </StyledSocialLinksContainer>
            </StyledFlexRow>
          </StyledFooterContent>
          <StyledCertificationsContainer>
            {certificationsCollection?.items.map((cert, index) => (
              <StyledImageLinkWrapper key={index}>
                <a href={cert?.url as string} target='_blank' rel='noreferrer'>
                  <img
                    src={cert?.image?.url as string}
                    alt={cert?.image?.description ?? (cert?.image?.title as string)}
                  />
                </a>
              </StyledImageLinkWrapper>
            ))}
          </StyledCertificationsContainer>
        </StyledFooterContainer>
      </StyledFooterWrapper>
      <StyledLegalFooter>
        <div className='legal-contents'>
          <p className='legal-text'>{legalText ?? '© 2024 Packetlabs. All rights reserved.'}</p>
          <div className='legal-links'>
            {legalLinksCollection?.items.map((link, index) => (
              <InternalLink key={index} {...(link?.linkTo as InternalLinkProps)}>
                {link?.label}
              </InternalLink>
            ))}
          </div>
        </div>
      </StyledLegalFooter>
    </>
  )
}

export default Footer
