import React from 'react'
import type { FC } from 'react'
import theme from '@/styles/theme'
import { StyledCta, StyledCtaContainer } from './styles'
import InternalLink from './InternalLink'
import type { ExternalLinkContent, InternalLinkContent } from '@/types'
import type { InternalLinkProps } from './InternalLink'

export const CallToAction: FC<(InternalLinkContent | ExternalLinkContent) & { className?: string }> = props => {
  const buttonStyleProps =
    props.style?.toLowerCase() !== 'button'
      ? {}
      : {
          $textColor: props.textColor ?? theme.colors.white,
          $backgroundColor: props.backgroundColor ?? theme.colors.devilsAdvocate,
          $borderColor: props.borderColor
        }
  if ((props as InternalLinkContent).linkTo?.slug) {
    const linkTo = (props as InternalLinkContent).linkTo as InternalLinkProps
    return (
      <StyledCta className={props.className} $textColor={props.textColor} {...buttonStyleProps}>
        <InternalLink className='button' {...linkTo}>
          {props.label}
        </InternalLink>
      </StyledCta>
    )
  }

  if ((props as ExternalLinkContent).url) {
    return (
      <StyledCta className={props.className} $textColor={props.textColor} {...buttonStyleProps}>
        <a href={(props as ExternalLinkContent).url as string} className='button' target='_blank' rel='noreferrer'>
          {props.label}
        </a>
      </StyledCta>
    )
  }
  return null
}

export const CallsToAction: FC<{
  className?: string
  items?: Array<InternalLinkContent | ExternalLinkContent> | null
}> = props =>
  props.items?.length ? (
    <StyledCtaContainer className={props.className}>
      {props.items.map((ctaProps, index) => (
        <CallToAction key={index} {...(ctaProps as InternalLinkContent | ExternalLinkContent)} />
      ))}
    </StyledCtaContainer>
  ) : null
