exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-templates-blog-post-index-tsx": () => import("./../../../src/templates/blog-post/index.tsx" /* webpackChunkName: "component---src-templates-blog-post-index-tsx" */),
  "component---src-templates-industry-page-index-tsx": () => import("./../../../src/templates/industry-page/index.tsx" /* webpackChunkName: "component---src-templates-industry-page-index-tsx" */),
  "component---src-templates-landing-page-index-tsx": () => import("./../../../src/templates/landing-page/index.tsx" /* webpackChunkName: "component---src-templates-landing-page-index-tsx" */),
  "component---src-templates-service-page-index-tsx": () => import("./../../../src/templates/service-page/index.tsx" /* webpackChunkName: "component---src-templates-service-page-index-tsx" */),
  "component---src-templates-solutions-page-index-tsx": () => import("./../../../src/templates/solutions-page/index.tsx" /* webpackChunkName: "component---src-templates-solutions-page-index-tsx" */),
  "component---src-templates-unique-page-learn-index-tsx": () => import("./../../../src/templates/unique-page/learn/index.tsx" /* webpackChunkName: "component---src-templates-unique-page-learn-index-tsx" */)
}

