import { styled, css } from 'styled-components'
import media from '@/styles/media'
import { Maybe } from '@/types'

export type StyledSectionProps = {
  $backgroundColor?: Maybe<string>
  $backgroundImage?: Maybe<string>
  $textColor?: Maybe<string>
  $borderColor?: Maybe<string>
}

export const textColorCss = css<StyledSectionProps>`
  ${({ $textColor }) => ($textColor ? `color: ${$textColor}` : '')};
`

export const backgroundCss = css<StyledSectionProps>`
  background-color: ${({ $backgroundColor }) => $backgroundColor ?? 'transparent'};

  ${({ $backgroundImage, $backgroundColor }) => {
    const bg = $backgroundColor ?? 'transparent'
    if ($backgroundImage) {
      return [
        `
        background-image: linear-gradient(to bottom, transparent 30%, ${bg}), linear-gradient(to top, transparent 30%, ${bg}), url(${
          $backgroundImage + '?w=768&fm=avif&q=60'
        });
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
      `,
        media.md`
      background-image: linear-gradient(to bottom, transparent 30%, ${bg}), linear-gradient(to top, transparent 30%, ${bg}), url(${
          $backgroundImage + '?w=1920&fm=avif&q=75'
        });
    `,
        media.tablet`
      background-image: linear-gradient(to bottom, transparent 30%, ${bg}), linear-gradient(to top, transparent 30%, ${bg}), linear-gradient(to left, transparent 90%, ${bg}), linear-gradient(to right, transparent 90%, ${bg}), url(${
          $backgroundImage + '?w=2880&fm=avif&q=755'
        });
    `,
        media.xl`
      background-image: linear-gradient(to bottom, transparent 30%, ${bg}), linear-gradient(to top, transparent 30%, ${bg}), linear-gradient(to left, transparent 90%, ${bg}), linear-gradient(to right, transparent 90%, ${bg}), url(${
          $backgroundImage + '?w=3840&fm=avif&q=75'
        });
    `
      ]
    }
    return ''
  }}
`

export const topPaddingTextCss = css`
  padding-top: 16px;
  ${media.md`
    padding-top: 24px;
  `}
`

export const bottomPaddingTextCss = css`
  padding-bottom: 16px;
  ${media.md`
    padding-bottom: 24px;
  `}
`

export const yPaddingTextCss = css`
  ${topPaddingTextCss}
  ${bottomPaddingTextCss}
`

export const xPaddingTextCss = css`
  padding-left: 16px;
  padding-right: 16px;
  ${media.md`
    padding-left: 24px;
    padding-right: 24px;
  `}
`

export const paddingTextCss = css`
  ${xPaddingTextCss}
  ${yPaddingTextCss}
`

export const xPaddingSectionCss = css`
  padding-left: 24px;
  padding-right: 24px;
  ${media.md`
    padding-left: 48px;
    padding-right: 48px;
  `}
`

export const topPaddingSectionCss = css`
  padding-top: 24px;
  ${media.md`
    padding-top: 48px;
  `}
`

export const bottomPaddingSectionCss = css`
  padding-bottom: 24px;
  ${media.md`
    padding-bottom: 48px;
  `}
`

export const yPaddingSectionCss = css`
  ${topPaddingSectionCss}
  ${bottomPaddingSectionCss}
`

export const paddingSectionCss = css`
  ${xPaddingSectionCss}
  ${yPaddingSectionCss}
`

export const maxWidthSectionCss = css`
  max-width: 1164px;
`

export const maxWidthPaddedSectionCss = css`
  max-width: calc(1164px + (24px * 2));
  ${media.md`
    max-width: calc(1164px + (48px * 2));
  `}
`

export const redOrWhite = css<StyledSectionProps>`
  ${({ theme, $backgroundColor }) =>
    $backgroundColor?.toLowerCase() === theme.colors.devilsAdvocate.toLowerCase()
      ? theme.colors.white
      : theme.colors.devilsAdvocate}
`

export const buttonCss = css<StyledSectionProps>`
  ${textColorCss}
  display: flex;
  align-items: center;
  justify-content: center;
  width: 256px;
  height: 46px;
  font-size: ${({ theme }) => theme.fontSizes.copy.large};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  ${({ $borderColor }) => ($borderColor ? `border: 1px solid ${$borderColor}` : 'border: none')};
  background-color: ${({ $backgroundColor }) => $backgroundColor};
  border-radius: ${({ theme }) => theme.borders.radius};
  &:hover {
    cursor: pointer;
  }
`

export const tableCss = css<StyledSectionProps>`
  table {
    ${maxWidthSectionCss}
    table-layout: fixed;
    overflow-x: auto;
    width: 100%;
    tbody {
      b {
        color: ${({ theme, $textColor }) => $textColor ?? theme.colors.white};
        font-weight: ${({ theme }) => theme.fontWeights.semiBold};
      }
      tr:first-child {
        th {
          ${topPaddingSectionCss}
          p {
            font-size: ${({ theme }) => theme.fontSizes.copy.small};
            ${media.md`
          font-size: ${({ theme }) => theme.fontSizes.copy.medium};
        `}
            padding-bottom: 0px;
          }
        }
      }
      tr:not(:first-child) {
        border-bottom: 1px solid ${({ $textColor, theme }) => $textColor ?? theme.colors.white};
        td:not(:first-child) {
          vertical-align: middle;
          text-align: center;
        }
        td p {
          ${topPaddingSectionCss}
          padding-bottom: 8px;
          ${media.md`
        padding-bottom: 16px;
      `}
        }
      }
      tr:hover {
        td p,
        b {
          color: ${redOrWhite};
        }
        border-color: ${redOrWhite};
      }
    }
  }
`

export const listCss = css<StyledSectionProps>`
  ul {
    ${yPaddingTextCss}
    box-sizing: border;
    li:first-child {
      margin-top: 0px;
    }
    li:last-child {
      margin-bottom: 0px;
    }
    li {
      margin-bottom: 8px;
      margin-top: 8px;
      position: relative;
      padding-left: 24px;
      &:before {
        content: '';
        display: block;
        position: absolute;
        top: 9px;
        left: 3px;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 4.5px;
        border-radius: 20px;
        border-color: ${({ $textColor }) => $textColor};
      }
      h1,
      h2,
      h3,
      h4,
      h5,
      h6,
      p {
        padding: 0px;
      }
    }
  }
`

export const marksCss = css<StyledSectionProps>`
  b {
    color: ${redOrWhite};
    font-weight: ${({ theme }) => theme.fontWeights.semiBold};
  }
  i {
    font-style: italic;
  }
  hr {
    border: none;
    border-bottom: 0.1rem solid ${({ $textColor, theme }) => $textColor ?? theme.colors.white};
    padding-top: 8px;
    padding-bottom: 8px;
  }
`

export const pCss = css<StyledSectionProps>`
  p {
    word-break: break-word;
    margin: auto;
    position: relative;
    z-index: 1;
    ${yPaddingTextCss}
    font-size: ${({ theme }) => theme.fontSizes.copy.medium};
    ${media.md`
      font-size: ${({ theme }) => theme.fontSizes.copy.large};
    `}
  }
  p:last-child:empty {
    padding-top: 0px;
    padding-bottom: 0px;
  }
`

export const fontCss = css<StyledSectionProps>`
  ${textColorCss}
  font-weight: ${({ theme }) => theme.fontWeights.regular};
  font-family: ${({ theme }) => theme.fonts.europaRegular};
`

export const headingsCss = css<StyledSectionProps>`
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    ${yPaddingTextCss}
    margin: auto;
    position: relative;
    z-index: 1;
  }
  h1 {
    font-size: ${({ theme }) => theme.fontSizes.headings.medium};
    ${media.sm`
      font-size: ${({ theme }) => theme.fontSizes.headings.large};
    `}
    ${media.md`
      font-size: ${({ theme }) => theme.fontSizes.headings.xxlarge};
    `}
  }
  h2 {
    font-size: ${({ theme }) => theme.fontSizes.headings.medium};
    ${media.md`
      font-size: ${({ theme }) => theme.fontSizes.headings.xlarge};
    `}
  }
  h3 {
    font-size: ${({ theme }) => theme.fontSizes.headings.small};
    ${media.md`
      font-size: ${({ theme }) => theme.fontSizes.headings.large};
    `}
  }
  h4 {
    font-size: ${({ theme }) => theme.fontSizes.headings.xsmall};
    ${media.md`
      font-size: ${({ theme }) => theme.fontSizes.headings.medium};
    `}
  }
  h5 {
    font-size: ${({ theme }) => theme.fontSizes.headings.xxsmall};
    ${media.md`
      font-size: ${({ theme }) => theme.fontSizes.headings.small};
    `}
  }
  h6 {
    font-size: ${({ theme }) => theme.fontSizes.copy.large};
    ${media.md`
      font-size: ${({ theme }) => theme.fontSizes.headings.xsmall};
    `}
  }
`

export const richTextCss = css<StyledSectionProps>`
  ${fontCss}
  ${pCss}
  ${listCss}
  ${tableCss}
  ${marksCss}
  ${headingsCss}
`

export const StyledPage = styled.div<StyledSectionProps>`
  color: ${({ $textColor }) => $textColor};
  background-color: ${({ $backgroundColor }) => $backgroundColor};
  width: 100vw;
  height: 100%;
`

export const StyledSectionDivider = styled.div<{ $borderColor?: string | null }>`
  ${maxWidthSectionCss}
  ${yPaddingSectionCss}
  ${({ $borderColor }) =>
    $borderColor
      ? `
    border-bottom: 1px solid ${$borderColor};
  `
      : null}
  margin: auto;
  width: calc(100vw - 48px);
  ${media.md`
    width: calc(100vw - 96px);
  `}
`

export const hubspotFormCss = css<
  StyledSectionProps & {
    $primaryBtnBackgroundColor?: string | null
    $primaryBtnTextColor?: string | null
  }
>`
  p {
    word-break: break-word;
    margin: auto;
    position: relative;
    z-index: 1;
    ${yPaddingTextCss}
    ${media.md`
      font-size: ${({ theme }) => theme.fontSizes.copy.medium};
    `}
  }
  p:last-child:empty {
    padding-top: 0px;
    padding-bottom: 0px;
  }
  label {
    margin-bottom: 8px;
    display: block;
    * {
      padding: 0;
    }
  }
  .hs-error-msg {
    color: ${({ theme }) => theme.colors.devilsAdvocate};
    font-size: ${({ theme }) => theme.fontSizes.copy.small};
  }
  .hs-error-msgs {
    padding: 0;
    li {
      padding: 2px;
    }
  }
  .hs_error_rollup {
    display: none;
  }
  .hs-input {
    color: ${({ theme }) => theme.colors.navalNight};
    background-color: #f5f8fa;
    box-sizing: border-box;
    width: 100%;
    border-radius: 10px;
    border: 1px solid #cbd6e2;
    height: 40px;
    max-width: 500px;
    padding: 0 16px;
    &.error {
      border-color: #c87872;
    }
    &[type='checkbox'],
    &[type='radio'] {
      cursor: pointer;
      appearance: auto;
      background: transparent;
      display: inline-block;
      color: #33475b;
      padding: 0;
      margin: 8px 6px 3px 0px;
      line-height: normal;
      border: 1px solid;
      border-radius: 2px;
      height: 13px;
      min-width: 13px;
    }
  }
  textarea.hs-input {
    padding: 10px 15px;
    line-height: 22px;
    height: auto;
  }
  *.is-placeholder {
    font-family: inherit;
    color: #bfbfbf;
  }
  .field {
    margin-bottom: 18px;
  }
  .actions {
    ${yPaddingTextCss}
  }
  .hs-button {
    ${buttonCss}
    background-color: ${({ $textColor }) => $textColor};
    color: ${({ $backgroundColor }) => $backgroundColor};
    text-align: center;
    margin: auto;
    &.primary {
      background-color: ${({ $primaryBtnBackgroundColor, $textColor, $backgroundColor }) =>
        $primaryBtnBackgroundColor === $backgroundColor ? $textColor : $primaryBtnBackgroundColor};
      color: ${({ $primaryBtnTextColor, $textColor, $backgroundColor }) =>
        $primaryBtnTextColor === $textColor ? $backgroundColor : $primaryBtnTextColor};
    }
  }
  .hs-form-required {
    color: ${redOrWhite};
  }
  a {
    &:link {
      text-decoration: underline;
    }
  }
  ul.inputs-list {
    padding: 0;
    li {
      padding: 0;
      &:before {
        display: none;
      }
    }
  }
`
