import { createGlobalStyle } from 'styled-components'
import theme from '@/styles/theme'

const { fonts, fontWeights, colors } = theme

const GlobalStyle = createGlobalStyle`
  html, body, div, span, applet, object, iframe,
  h1, h2, h3, h4, h5, h6, p, blockquote, pre,
  a, abbr, acronym, address, big, cite, code,
  del, dfn, em, img, ins, kbd, q, s, samp,
  small, strike, strong, sub, sup, tt, var,
  b, u, i, center,
  dl, dt, dd, ol, ul, li,
  fieldset, form, label, legend,
  table, caption, tbody, tfoot, thead, tr, th, td,
  article, aside, canvas, details, embed,
  figure, figcaption, footer, header, hgroup,
  menu, nav, output, ruby, section, summary,
  time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font: inherit;
    vertical-align: baseline;
  }
  html {
    box-sizing: border-box;
  }
  body {
    font-family: ${fonts.europaRegular};
    color: ${colors.white};
    line-height: 1;
    background-color: ${colors.navalNight};
    overflow-x: hidden;
  }
  *,
  *::before,
  *::after {
    box-sizing: inherit;
    color: inherit;
    font-size: inherit;
    -webkit-font-smoothing: antialiased;
    margin: 0;
    padding: 0;
  }
  h1, h2, h3, h4, h5, h6 {
    font-family: ${fonts.europaBold};
    font-weight: ${fontWeights.bold};
  }
  p {
    line-height: 1.5;
  }
  a {
    &:link, &:visited {
      text-decoration: none;
    }
    &:hover, &:active {
      outline: 0;
    }
  }
  a:not(.button) {
    &:hover {
      color: #FD3842;
    }
  }
  ol, ul {
    list-style: none;
  }
  blockquote, q {
    quotes: none;
  }
  blockquote:before,
  blockquote:after,
  q:before,
  q:after {
    content: '';
    content: none;
  }
  table {
    border-collapse: collapse;
    border-spacing: 0;
  }
  audio, canvas, video {
    display: inline-block;
    max-width: 100%;
    zoom: 1;
  }
  input {
    all: unset;
  }
`
export default GlobalStyle
