import { css, styled } from 'styled-components'
import media from '@/styles/media'
import { NAV_HEIGHT } from '../styles'
import { paddingSectionCss } from '@/styles/shared'

const mobileNavItemCss = css`
  &.top-level-item {
    font-size: ${({ theme }) => theme.fontSizes.copy.xlarge};
  }
  &:not(.top-level-item) {
    padding-left: 24px;
  }
  &.primary-cta {
    display: block;
    padding-left: 0;
    margin-left: 24px;
    width: fit-content;
    span.red-arrow {
      color: ${({ theme }) => theme.colors.devilsAdvocate};
    }
  }
  display: flex;
  gap: 12px;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 100%;
  font-size: inherit;
  font-family: inherit;
  background: none;
  border: none;
  color: ${({ theme }) => theme.colors.white};
  &:hover {
    color: ${({ theme }) => theme.colors.devilsAdvocate};
    text-decoration: underline;
    cursor: pointer;
  }
  ${media.tablet`
    display: none;
  `}
`

export const StyledMobileSubNavContainer = styled.div`
  ${paddingSectionCss}
  width: 100%;
  background-color: ${({ theme }) => theme.colors.subnautical};
  height: calc(100vh - ${NAV_HEIGHT});
  overflow: auto;
  ${media.tablet`
    display: none;
  `}
`

export const StyledToggleButton = styled.button`
  ${mobileNavItemCss}
  span.arrow-down,
  span.arrow-up {
    justify-content: center;
    align-items: center;
    display: flex;
    align-self: center;
    &:hover {
      cursor: pointer;
    }
    &:after {
      z-index: 10;
      content: '';
      border-style: solid;
      transition: all 0.3s ease-out;
    }
    ${media.tablet`
      display: none;
    `}
  }
  span.arrow-down {
    &:after {
      border-width: 9px 6px 0 6px;
      border-color: ${({ theme }) => `${theme.colors.white} transparent transparent transparent`};
    }
  }
  span.arrow-up {
    &:after {
      border-width: 0 6px 9px 6px;
      border-color: ${({ theme }) => `transparent transparent ${theme.colors.white} transparent`};
    }
  }
`

export const StyledMobileNavItemsContainer = styled.div<{ $nested?: boolean }>`
  ${({ $nested }) => ($nested ? 'margin-left: 24px;' : '')}
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 48px;
  font-family: ${({ theme }) => theme.fonts.europaRegular};
  font-size: ${({ theme }) => theme.fontSizes.copy.large};
  div.toggle-group {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 48px;
    ${media.tablet`
      display: none;
    `}
  }
  div.nested-nav-group {
    display: flex;
    flex-direction: column;
    gap: 48px;
    ${media.tablet`
      display: none;
    `}
  }
  h5.group-title {
    font-weight: ${({ theme }) => theme.fontWeights.light};
    color: ${({ theme }) => theme.colors.beluga};
    display: block;
    text-decoration: underline;
    text-decoration-color: ${({ theme }) => theme.colors.devilsAdvocate};
    text-underline-offset: 0.4em;
    ${media.tablet`
      display: none;
    `}
  }
  a {
    ${mobileNavItemCss}
    text-decoration: underline;
    text-underline-offset: 0.2em;
  }
  ${media.tablet`
    display: none;
  `}
`

export const StyledMobileNav = styled.div`
  padding: 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  ${media.tablet`
    display: none;
  `}
`

export const StyledHamburgerButton = styled.button<{ $active: boolean }>`
  border: none;
  background-color: inherit;
  display: flex;
  align-items: center;
  width: 36px;
  height: 36px;
  cursor: pointer;
  span.icon {
    display: block;
    position: relative;
    width: 36px;
    height: 3px;
    border-radius: 4px;
    background: ${({ $active }) => ($active ? 'transparent' : '#ffffff')};
    &::before,
    &::after {
      content: '';
      display: block;
      position: absolute;
      width: 36px;
      height: 3px;
      border-radius: 4px;
      transition: all 0.3s ease-in-out;
      background-color: #ffffff;
    }
    &::before {
      top: ${({ $active }) => ($active ? 0 : '-10px')};
      transform: ${({ $active }) => ($active ? 'rotate(45deg)' : 'none')};
    }
    &::after {
      bottom: ${({ $active }) => ($active ? 0 : '-10px')};
      transform: ${({ $active }) => ($active ? 'rotate(-45deg)' : 'none')};
    }
  }
  ${media.tablet`
    display: none;
  `}
`
