import React, { FC, useEffect, useMemo, useRef, useState } from 'react'

declare const window: {
  hbspt?: {
    forms: {
      create(options: Record<string, string>): void
    }
  }
} & Window

type Props = {
  id?: string
  options: Record<string, string | undefined>
}

const DEFAULT_HUB_ID = '20040397'

let globalCount = 0

const HubspotForm: FC<Props> = props => {
  const [isLoaded, setIsLoaded] = useState(false)
  const elRef = useRef(null)
  const elId = useMemo(() => {
    if (!props.id) globalCount++
    return props.id ?? `hubspot_form_${globalCount}`
  }, [props.id])
  useEffect(() => {
    const timers: NodeJS.Timeout[] = []
    const createForm = () => {
      if (window.hbspt) {
        // protect against component unmounting before window.hbspt is available
        if (elRef.current === null) {
          return
        }

        const formInstanceId = self.crypto.randomUUID()

        const options = {
          ...props.options,
          portalId: DEFAULT_HUB_ID,
          formInstanceId,
          target: `#${(elRef.current as HTMLDivElement).getAttribute(`id`)}`
        }

        window.hbspt.forms.create(options)
      } else {
        timers.push(setTimeout(createForm, 1))
      }
    }

    const findFormElement = () => {
      // protect against component unmounting before form is added
      if (elRef.current === null) {
        return
      }
      const form = (elRef.current as HTMLDivElement).querySelector(`iframe`)
      if (form) {
        setIsLoaded(true)
      } else {
        timers.push(setTimeout(findFormElement, 1))
      }
    }

    const loadScript = () => {
      const script = document.createElement(`script`)
      script.defer = true
      script.onload = () => {
        createForm()
        findFormElement()
      }
      script.src = `//js.hsforms.net/forms/v2.js`
      document.head.appendChild(script)
    }

    if (!window.hbspt) {
      loadScript()
    } else {
      createForm()
      findFormElement()
    }

    return () => {
      timers.forEach(t => {
        clearTimeout(t)
      })
    }
    // We only want this called once...
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return <div ref={elRef} id={elId} style={{ display: isLoaded ? 'block' : 'none' }} />
}

export default HubspotForm
