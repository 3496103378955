import React, { useState, useEffect } from 'react'
import { Link } from 'gatsby'
import { useLocation } from '@reach/router'
import { AnimatePresence, motion } from 'framer-motion'
import MobileSubNav from './MobileSubNav'
import { StyledHamburgerButton, StyledMobileNav } from './styles'
import { StyledLogoContainer } from '../styles'

import type { FC } from 'react'
import type { NavBarContent, NavGroupItemContent } from '@/types'
import type { NavLinkItemContent } from '../NavItem'

const MobileNav: FC<NavBarContent> = props => {
  const [mobileNavOpen, setMobileNavOpen] = useState(false)
  const { companyLogo, callToAction, itemsCollection } = props
  const callToActionProps = callToAction
    ? callToAction
    : {
        __typename: 'CMS_InternalLink',
        label: 'Contact Us',
        linkTo: {
          __typename: 'CMS_LandingPage',
          slug: 'contact-us'
        }
      }
  const navItems = itemsCollection?.items as NavGroupItemContent[]
  const location = useLocation()
  useEffect(() => {
    setMobileNavOpen(false)
  }, [location.pathname])
  return (
    <>
      <StyledMobileNav>
        <StyledLogoContainer>
          <Link to='/'>
            <img
              alt={(companyLogo?.description ?? companyLogo?.title) as string}
              src={(companyLogo?.url + '?q=100') as string}
            />
          </Link>
        </StyledLogoContainer>
        <StyledHamburgerButton
          $active={mobileNavOpen}
          onClick={() => {
            setMobileNavOpen(!mobileNavOpen)
          }}
        >
          <span className='icon' />
        </StyledHamburgerButton>
      </StyledMobileNav>
      <AnimatePresence mode='wait' initial={false}>
        {mobileNavOpen ? (
          <motion.div
            key='mobile-sub-nav'
            initial={{ x: '-100%' }}
            animate={{ x: 0 }}
            transition={{ ease: 'linear', duration: 0.2 }}
            exit={{ x: '-100%' }}
          >
            <MobileSubNav callToAction={callToActionProps as NavLinkItemContent} items={navItems} />
          </motion.div>
        ) : null}
      </AnimatePresence>
    </>
  )
}

export default MobileNav
