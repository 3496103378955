import React from 'react'
import type { GatsbyBrowser } from 'gatsby'
import { ThemeProvider } from 'styled-components'
import theme from '@/styles/theme'
import GlobalStyle from '@/styles/GlobalStyle'
import Nav from '@/components/NavBar'
import Footer from '@/components/Footer'
import type { FooterContent, NavBarContent } from '@/types'

export const wrapPageElement: GatsbyBrowser['wrapPageElement'] = ({ element, props }) => {
  const footerProps = props.pageContext.footerProps as FooterContent
  const navBarProps = props.pageContext.navBarProps as NavBarContent
  return (
    <>
      <GlobalStyle />
      <Nav {...navBarProps} />
      {element}
      <Footer {...footerProps} />
    </>
  )
}

export const wrapRootElement: GatsbyBrowser['wrapRootElement'] = ({ element }) => {
  return <ThemeProvider theme={theme}>{element}</ThemeProvider>
}
