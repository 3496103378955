import React from 'react'
import type { FC, PropsWithChildren } from 'react'
import { InternalLink } from '@/components/PageSections/sections/CallToAction'
import type { ExternalLinkContent, InternalLinkContent, WithTypename } from '@/types'
import type { InternalLinkProps } from '@/components/PageSections/sections/CallToAction/InternalLink'

export type NavLinkItemContent = WithTypename<
  InternalLinkContent | ExternalLinkContent,
  'CMS_InternalLink' | 'CMS_ExternalLink'
>

const NavItem: FC<
  PropsWithChildren<
    NavLinkItemContent & {
      className?: string
    }
  >
> = props => {
  if (props.__typename === 'CMS_InternalLink') {
    return (
      <InternalLink className={props.className} {...((props as InternalLinkContent).linkTo as InternalLinkProps)}>
        {props.label}
        {props.children}
      </InternalLink>
    )
  }
  if (props.__typename === 'CMS_ExternalLink') {
    return (
      <a
        className={props.className}
        href={(props as ExternalLinkContent).url as string}
        target='_blank'
        rel='noopener noreferrer'
      >
        {props.label}
        {props.children}
      </a>
    )
  }
  return null
}

export default NavItem
