import React from 'react'
import useScrollTrigger from '@mui/material/useScrollTrigger'
import { StyledNavWrapper } from './styles'

import type { FC } from 'react'
import type { NavBarContent } from '@/types'
import MobileNav from './MobileNav'
import DesktopNav from './DesktopNav'

const NavBar: FC<NavBarContent> = props => {
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 1
  })
  return (
    <StyledNavWrapper className={trigger ? 'active' : ''}>
      <MobileNav {...props} />
      <DesktopNav {...props} />
    </StyledNavWrapper>
  )
}

export default NavBar
