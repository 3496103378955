import { styled } from 'styled-components'

export const NAV_HEIGHT = '60px'

export const StyledNavWrapper = styled.nav`
  position: sticky;
  top: 0;
  z-index: 3;
  line-height: 1.5;
  background-color: transparent;
  height: ${NAV_HEIGHT};
  &:hover {
    background-color: ${({ theme }) => theme.colors.subnautical};
  }
  &.active {
    background-color: ${({ theme }) => theme.colors.subnautical};
  }
`

export const StyledLogoContainer = styled.div`
  display: flex;
  align-items: center;
`
