import React, { useState, useEffect } from 'react'
import { Link } from 'gatsby'
import { AnimatePresence, motion } from 'framer-motion'
import { useLocation } from '@reach/router'
import {
  StyledDesktopNav,
  StyledNavItemsContainer,
  StyledNavContents,
  StyledSubNavContainer,
  StyledDesktopSubNavWrapper
} from './styles'
import { StyledLogoContainer } from '../styles'
import NavGroup from './NavGroup'
import NavItem from '../NavItem'

import type { FC } from 'react'
import type { NavLinkItemContent } from '../NavItem'
import type { NavBarContent, NavGroupContent, NavGroupItemContent } from '@/types'

const DesktopNav: FC<NavBarContent> = props => {
  const [activeSubNav, setActiveSubNav] = useState<number | null>(null)
  const location = useLocation()

  useEffect(() => {
    setActiveSubNav(null)
  }, [location.pathname])

  const { companyLogo, itemsCollection } = props
  const navItems = (itemsCollection?.items ?? []) as NavGroupItemContent[]

  const callToActionProps = props.callToAction
    ? props.callToAction
    : {
        __typename: 'CMS_InternalLink',
        label: 'Contact Us',
        linkTo: {
          __typename: 'CMS_LandingPage',
          slug: 'contact-us'
        }
      }

  return (
    <>
      <StyledDesktopNav>
        <StyledNavContents>
          <StyledLogoContainer>
            <Link to='/'>
              <img
                alt={(companyLogo?.description ?? companyLogo?.title) as string}
                src={(companyLogo?.url + '?q=100') as string}
              />
            </Link>
          </StyledLogoContainer>
          <StyledNavItemsContainer>
            {navItems.map((navItem, index) => {
              if (navItem.__typename === 'CMS_NavGroup') {
                return (
                  <button
                    key={index}
                    onClick={() => {
                      if (activeSubNav === index) {
                        setActiveSubNav(null)
                      } else {
                        setActiveSubNav(index)
                      }
                    }}
                  >
                    {(navItem as NavGroupContent).title}
                  </button>
                )
              } else {
                return <NavItem key={index} {...(navItem as NavLinkItemContent)} />
              }
            })}
          </StyledNavItemsContainer>
          {callToActionProps ? (
            <div className='primary-cta'>
              <NavItem {...(callToActionProps as NavLinkItemContent)} />
            </div>
          ) : null}
        </StyledNavContents>
      </StyledDesktopNav>
      <StyledDesktopSubNavWrapper>
        <AnimatePresence mode='wait' initial={false}>
          {activeSubNav !== null ? (
            <motion.div
              key={activeSubNav ?? 'closed'}
              style={{ overflow: 'hidden' }}
              initial={{ height: 0 }}
              animate={{ height: 'auto' }}
              transition={{ ease: 'anticipate', duration: 0.3 }}
              exit={{ height: 0 }}
            >
              <StyledSubNavContainer>
                <NavGroup
                  handleClose={() => {
                    setActiveSubNav(null)
                  }}
                  callToAction={(navItems[activeSubNav] as NavGroupContent)?.callToAction as NavLinkItemContent}
                  items={(navItems[activeSubNav] as NavGroupContent)?.itemsCollection?.items as NavGroupContent[]}
                />
              </StyledSubNavContainer>
            </motion.div>
          ) : null}
        </AnimatePresence>
      </StyledDesktopSubNavWrapper>
    </>
  )
}

export default DesktopNav
