import React, { useMemo, useState } from 'react'
import { AnimatePresence, motion } from 'framer-motion'
import NavItem from '../NavItem'
import {
  StyledNavGroupAnimationContainer,
  StyledNavGroupColumn,
  StyledNavGroupContainer,
  StyledNavGroupRow,
  StyledNavGroupTabs,
  StyledPrimaryCtaWrapper
} from './styles'

import type { FC } from 'react'
import type { NavGroupContent, NavGroupItemContent } from '@/types'
import type { NavLinkItemContent } from '../NavItem'

const NavGroup: FC<{
  callToAction?: NavLinkItemContent | null
  items?: NavGroupItemContent[]
  handleClose: () => void
}> = (props): JSX.Element => {
  const [openTab, setOpenTab] = useState(0)

  const handleClick = (i: number) => {
    if (i !== openTab) setOpenTab(i)
  }

  const { activeSubNav, navGroups, primaryCta } = useMemo(() => {
    const { navLinks, navGroups } = (props.items ?? []).reduce(
      (
        acc: {
          navLinks: NavLinkItemContent[]
          navGroups: Partial<NavGroupContent>[]
        },
        navItem
      ) => {
        switch (navItem.__typename) {
          case 'CMS_NavGroup':
            acc.navGroups.push(navItem as NavGroupContent)
            break
          case 'CMS_InternalLink':
          case 'CMS_ExternalLink':
            acc.navLinks.push(navItem as NavLinkItemContent)
        }
        return acc
      },
      {
        navLinks: [],
        navGroups: []
      }
    )
    // If there are both navGroups and navLinks at the top level, we need a tab to put the navLinks under
    if (navGroups.length > 0 && navLinks.length > 0) {
      navGroups.push({
        title: '...',
        itemsCollection: {
          // Satisfying Generated Types...
          limit: navLinks.length,
          skip: 0,
          total: navLinks.length,
          items: navLinks as NavGroupItemContent[]
        }
      })
    }

    // If there are no navGroups, then always show the top level navLinks
    const activeSubNav: NavGroupItemContent[] = navGroups.length
      ? ((navGroups[openTab].itemsCollection?.items ?? []) as NavGroupItemContent[])
      : navLinks

    const primaryCta = navGroups[openTab]?.callToAction ?? props.callToAction

    return {
      activeSubNav,
      navGroups,
      primaryCta
    }
  }, [props.items, props.callToAction, openTab])

  return (
    <StyledNavGroupContainer>
      <StyledNavGroupTabs>
        {navGroups?.map((item, i) => (
          <button key={i} className={i === openTab ? 'active' : ''} onClick={() => handleClick(i)}>
            {item.title ?? ''}
          </button>
        ))}
        <button
          className='close'
          aria-label='close'
          onClick={() => {
            props.handleClose()
          }}
        >
          Close ✕
        </button>
      </StyledNavGroupTabs>
      <StyledNavGroupAnimationContainer>
        <AnimatePresence mode='wait' initial={false}>
          <StyledNavGroupRow
            as={motion.div}
            key={openTab ?? 'nil'}
            initial={{ opacity: 0, height: 0 }}
            animate={{ opacity: 1, height: 'auto' }}
            transition={{ ease: 'easeIn', duration: 0.3 }}
            exit={{ opacity: 0, height: 0 }}
          >
            {activeSubNav?.map((navItem, index) => {
              if (navItem.__typename === 'CMS_NavGroup') {
                const { title: groupTitle, itemsCollection } = navItem as NavGroupContent

                const nestedItems = (itemsCollection?.items ?? []) as NavGroupItemContent[]

                return (
                  <StyledNavGroupColumn key={index}>
                    {groupTitle ? <h5 className='group-title'>{groupTitle}</h5> : null}
                    {nestedItems.map((nestedItem, nestedIndex) => (
                      <NavItem key={nestedIndex} {...(nestedItem as NavLinkItemContent)} />
                    ))}
                  </StyledNavGroupColumn>
                )
              } else {
                return <NavItem key={index} {...(navItem as NavLinkItemContent)} />
              }
            })}
            {primaryCta ? (
              <StyledPrimaryCtaWrapper>
                <NavItem className='primary-cta' {...(primaryCta as NavLinkItemContent)}>
                  <span className='chev-right' />
                </NavItem>
              </StyledPrimaryCtaWrapper>
            ) : null}
          </StyledNavGroupRow>
        </AnimatePresence>
      </StyledNavGroupAnimationContainer>
    </StyledNavGroupContainer>
  )
}

export default NavGroup
