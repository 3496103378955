const theme = {
  borders: {
    radius: '20px'
  },
  // Color names generated from https://colornamer.robertcooper.me/
  colors: {
    white: '#FFFFFF',
    black: '#000000',
    // Blues
    cottonBall: '#F0F4FE',
    insigniaWhite: '#EDF3FA',
    blueReflection: '#CBD6E2',
    compassBlue: '#33475b',
    subnautical: '#00274F',
    navalNight: '#001A38',
    // Greys
    titaniumWhite: '#E4E4E4',
    silver: '#BFBFBF',
    beluga: '#F1F1F1',
    squant: '#666666',
    // Reds
    lovable: '#C87872',
    devilsAdvocate: '#FD3842'
  },
  fonts: {
    europaBold: 'Europa, sans-serif',
    europaRegular: 'Europa, sans-serif'
  },
  fontWeights: {
    extraLight: 200,
    light: 300,
    regular: 400,
    medium: 500,
    semiBold: 600,
    bold: 700
  },
  fontSizes: {
    copy: {
      xsmall: '0.75rem',
      small: '0.875rem',
      medium: '1rem',
      large: '1.125rem',
      xlarge: '1.5rem',
      xxlarge: '1.875rem'
    },
    headings: {
      xxsmall: '1.25rem',
      xsmall: '1.5rem',
      small: '2.25rem',
      medium: '3rem',
      large: '3.5rem',
      xlarge: '4rem',
      xxlarge: '4.5rem'
    }
  }
}

export default theme
