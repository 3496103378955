import React from 'react'
import type { FC, PropsWithChildren } from 'react'
import { Link } from 'gatsby'

import type { InternalLinkToContent, LandingPageContent, SolutionsPageContent, WithTypename } from '@/types'

export type PageType =
  | 'CMS_LandingPage'
  | 'CMS_SolutionsPage'
  | 'CMS_IndustryPage'
  | 'CMS_ServicePage'
  | 'CMS_BlogPost'
  | 'CMS_UniquePage'

type SolutionsType = 'Services' | 'Industries' | 'Roles'

type PageDetails = {
  pageType: PageType
  slug: string
  isRootPage?: boolean
  solutionsType?: SolutionsType
}

export type InternalLinkProps = WithTypename<InternalLinkToContent, PageType>

const getInternalPath = ({ pageType, slug, isRootPage, solutionsType }: PageDetails) => {
  switch (pageType) {
    case 'CMS_LandingPage':
      return isRootPage ? '/' : `/${slug}`
    case 'CMS_SolutionsPage':
      return `/${solutionsType?.toLowerCase()}/${slug}`
    case 'CMS_IndustryPage':
      return `/industries/${slug}`
    case 'CMS_ServicePage':
      return `/services/${slug}`
    case 'CMS_UniquePage':
      return `/${slug}`
    case 'CMS_BlogPost':
      return `/posts/${slug}`
  }
}

const InternalLink: FC<PropsWithChildren<InternalLinkProps & { className?: string }>> = props => {
  const isRootPage = (props as WithTypename<LandingPageContent, 'CMS_LandingPage'>).isRootPage === 'yes'

  const solutionsType = (props as WithTypename<SolutionsPageContent, 'CMS_SolutionsPage'>)
    .solutionType as SolutionsType

  return (
    <Link
      className={props.className}
      to={getInternalPath({
        pageType: props.__typename,
        slug: props.slug as string,
        isRootPage,
        solutionsType
      })}
    >
      {props.children}
    </Link>
  )
}

export default InternalLink
