import { styled } from 'styled-components'
import {
  StyledSectionProps,
  backgroundCss,
  buttonCss,
  textColorCss,
  yPaddingTextCss
} from '../../../../styles/shared'

export const StyledCta = styled.div<StyledSectionProps>`
  ${textColorCss}
  ${backgroundCss}
  ${({ $borderColor }) => ($borderColor ? `border: 1px solid ${$borderColor};` : '')}
  border-radius: ${({ theme }) => theme.borders.radius};
  position: relative;
  z-index: 1;
  .button {
    ${buttonCss}
    border: none;
    position: relative;
    z-index: 1;
    transition: 0.3s;
  }
  transition: background-color 1s;
  &:hover {
    ${({ $textColor, $backgroundColor }) => {
      if ($textColor && $backgroundColor) {
        return `
        background-color: ${$textColor};
        color: ${$backgroundColor};
        `
      }
      return 'opacity: 0.9;'
    }}
  }
`

export const StyledCtaContainer = styled.div`
  ${yPaddingTextCss}
  max-width: 872px;
  position: relative;
  z-index: 1;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  column-gap: 32px;
  row-gap: 24px;
`
